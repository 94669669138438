import React, { Component } from "react";
import { hot } from "react-hot-loader";

class Board extends Component {
  constructor(props) {
    super(props);
    this.size = this.props.size;
  }

  render() {
    const {
      black,
      black_time_limit,
      black_stone_count,
      black_win_times,
      white,
      white_time_limit,
      white_stone_count,
      white_win_times,
    } = this.props;
    return (
      <div className="board">
        <div className="board-rows">
          {Array.from(Array(this.size).keys()).map((_, i) => (
            <div key={i} className="board-row">
              {Array.from(Array(this.size).keys()).map((_, j) => (
                <div key={i + "_" + j} className="board-cell">
                  {this.props.board[i][j] === 1 ? (
                    <div className="black-move"></div>
                  ) : this.props.board[i][j] === -1 ? (
                    <div className="white-move"></div>
                  ) : null}
                  {this.props.last_move !== undefined &&
                  (i === this.props.last_move[0]) &&
                    (j === this.props.last_move[1]) ? (
                    <div className="last-move"></div>
                  ) : null}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="board-status">
          <div>
            {black.includes("#") ? (
              <>
                <div>({black.split("#")[1]})</div>
                <div>黑：{black.split("#")[0]}</div>
              </>
            ) : (
              <div>黑：{black}</div>
            )}
            <div>剩餘時間：{black_time_limit}</div>
            <div>數量：{black_stone_count}</div>
            <div>勝數：{black_win_times}</div>
          </div>
          <hr style={{ width: "100%" }}></hr>
          <div>
            {white.includes("#") ? (
              <>
                <div>({white.split("#")[1]})</div>
                <div>白：{white.split("#")[0]}</div>
              </>
            ) : (
              <div>白：{white}</div>
            )}
            <div>剩餘時間：{white_time_limit}</div>
            <div>數量：{white_stone_count}</div>
            <div>勝數：{white_win_times}</div>
          </div>
        </div>
      </div>
    );
  }
}

export default hot(module)(Board);
