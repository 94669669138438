import config from "./config.json";

const login = async (username, password) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("username", username);
    formData.append("password", password);
    fetch(config.server_url + "/admin_login", {
      method: "POST",
      header: { "Content-Type": "multipart/form-data" },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          reject();
        }
      })
      .then((token) => {
        window.localStorage.setItem("token", token);
        resolve(token);
      });
  });
};

const competition_list = async () => {
  return new Promise((resolve, reject) => {
    fetch(config.server_url + "/competition_list", {
      method: "POST",
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          reject();
        }
      })
      .then((data) => {
        resolve(data);
      });
  });
};

const competition_history = async (competition_id) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("competition_id", competition_id);
    fetch(config.server_url + "/competition_history", {
      method: "POST",
      header: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          reject();
        }
      })
      .then((data) => {
        resolve(data);
      });
  });
};

const download_history = async (competition_id, game_id, filename) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("competition_id", competition_id);
    formData.append("game_id", game_id);
    fetch(config.server_url + "/download_history", {
      method: "POST",
      header: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.blob();
        } else {
          reject();
        }
      })
      .then((blob) => {
        if (blob !== undefined) {
          require("downloadjs")(blob, filename, "text/plain");
          resolve();
        }
      });
  });
};

const create_competition = async (name, type, time_limit, board_size) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("competition_name", name);
    formData.append("competition_type", type);
    formData.append("time_limit", time_limit);
    formData.append("board_size", board_size);
    formData.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    fetch(config.server_url + "/create_competition", {
      method: "POST",
      header: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    }).then((res) => {
      if (res.ok) {
        resolve();
      } else {
        reject();
      }
    });
  });
};

const delete_competition = async (name) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("competition_name", name);
    formData.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    fetch(config.server_url + "/delete_competition", {
      method: "POST",
      header: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    }).then((res) => {
      if (res.ok) {
        resolve();
      } else {
        reject();
      }
    });
  });
};

const competition_info = async (competition_name) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("competition_name", competition_name);
    fetch(config.server_url + "/competition_info", {
      method: "POST",
      header: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          reject();
        }
      })
      .then((data) => {
        resolve(data);
      });
  });
};

const ws_connect = async (
  competition_name,
  onInit,
  onAddPlayer,
  onRemovePlayer,
  onStart,
  onUpdateBoard,
  onUpdateTimeLimit,
  onUpdateStoneCount,
  onUpdateWinTimes,
  onUpdateScoreboard,
  onUpdateTree,
  onEndGame,
  onEndCompetition,
  onChangeColor
) => {
  return new Promise((resolve, reject) => {
    let ws = new WebSocket(config.wss_url);
    ws.onopen = () => {
      console.log("open connection");
      resolve(ws);
      let join = {
        action: "join",
        data: {
          source: "viewer",
          competition: competition_name,
        },
      };
      ws.send(JSON.stringify(join));
    };
    ws.onmessage = (event) => {
      let data = JSON.parse(event.data);
      if (data.action === "init") {
        onInit(
          data.data.status,
          data.data.player_list,
          data.data.board,
          data.data.last_move,
          data.data.board_end,
          data.data.time_limit,
          data.data.stone_count,
          data.data.win_times,
          data.data.scoreboard,
          data.data.game_tree,
          data.data.player_color
        );
      } else if (data.action === "add_player") {
        onAddPlayer(data.data);
      } else if (data.action === "remove_player") {
        onRemovePlayer(data.data);
      } else if (data.action === "start") {
        onStart();
      } else if (data.action === "update_board") {
        onUpdateBoard(
          data.data.game_id,
          data.data.board,
          data.data.last_position
        );
      } else if (data.action === "update_time_limit") {
        onUpdateTimeLimit(
          data.data.game_id,
          data.data.black_time_limit,
          data.data.white_time_limit
        );
      } else if (data.action === "update_stone_count") {
        onUpdateStoneCount(
          data.data.game_id,
          data.data.black_stone_count,
          data.data.white_stone_count
        );
      } else if (data.action === "update_win_times") {
        onUpdateWinTimes(
          data.data.game_id,
          data.data.black_win_times,
          data.data.white_win_times
        );
      } else if (data.action === "update_score_board") {
        onUpdateScoreboard(data.data.scoreboard);
      } else if (data.action === "update_tree") {
        onUpdateTree(data.data.game_tree);
      } else if (data.action === "end_game") {
        onEndGame(data.data.game_id);
      } else if (data.action === "end_competition") {
        onEndCompetition();
      } else if (data.action === "change_color") {
        onChangeColor(data.data.game_id, data.data.black, data.data.white);
      }
    };
    ws.onclose = () => {
      console.log("close connection");
    };
  });
};

const remove_player = async (competition_id, player_id) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("competition", competition_id);
    formData.append("player", player_id);
    formData.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    fetch(config.server_url + "/remove_player", {
      method: "POST",
      header: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    }).then((res) => {
      if (res.ok) {
        resolve();
      } else {
        reject();
      }
    });
  });
};

const start_competition = async (competition_id) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("competition_name", competition_id);
    formData.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    fetch(config.server_url + "/start_competition", {
      method: "POST",
      header: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    }).then((res) => {
      if (res.ok) {
        resolve();
      } else {
        reject();
      }
    });
  });
};

const restart_game = async (competition_id, game_id) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("competition_name", competition_id);
    formData.append("game_id", game_id);
    formData.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    fetch(config.server_url + "/restart_game", {
      method: "POST",
      header: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          reject();
        }
      })
      .then((data) => {
        resolve(data);
      });
  });
};

const assign_winner = async (competition_id, game_id, winner) => {
  return new Promise((resolve, reject) => {
    let formData = new FormData();
    formData.append("competition_name", competition_id);
    formData.append("game_id", game_id);
    formData.append("winner", winner);
    formData.append(
      "Authorization",
      "Bearer " + window.localStorage.getItem("token")
    );
    fetch(config.server_url + "/assign_winner", {
      method: "POST",
      header: {
        "Content-Type": "multipart/form-data",
      },
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          return res.text();
        } else {
          reject();
        }
      })
      .then((data) => {
        resolve(data);
      });
  });
};

export {
  login,
  competition_list,
  competition_history,
  download_history,
  create_competition,
  delete_competition,
  competition_info,
  ws_connect,
  remove_player,
  start_competition,
  restart_game,
  assign_winner,
};
