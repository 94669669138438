import React, { Component } from "react";
import { hot } from "react-hot-loader";
import CompetitionPrepare from "./Competition/CompetitionPrepare";
import CompetitionStart from "./Competition/CompetitionStart";
import { competition_info, competition_history, ws_connect } from "./api";

class Competition extends Component {
  constructor() {
    super();
    this.state = {
      initialize: false,
      status: "prepare",
      type: "",
      board_size: 8,
      player_list: {},
      board: {},
      last_move: {},
      board_end: {},
      time_limit: {},
      stone_count: {},
      win_times: {},
      scoreboard: {},
      game_tree: undefined,
      player_color: {},
      history: undefined,
      history_time: undefined,
    };
  }

  onInit = (
    status,
    player_list,
    board,
    last_move,
    board_end,
    time_limit,
    stone_count,
    win_times,
    scoreboard,
    game_tree,
    player_color
  ) => {
    let tmp = this.state.player_list;
    player_list.forEach((player) => {
      tmp[player] = undefined;
    });
    this.setState({
      initialize: true,
      status: status,
      player_list: tmp,
      board: board,
      last_move: last_move,
      board_end: board_end,
      time_limit: time_limit,
      stone_count: stone_count,
      win_times: win_times,
      scoreboard: scoreboard,
      game_tree: game_tree,
      player_color: player_color,
    });
  };
  onAddPlayer = (player_id) => {
    let tmp = this.state.player_list;
    tmp[player_id] = undefined;
    this.setState({ player_list: tmp });
  };
  onRemovePlayer = (player_id) => {
    let tmp = this.state.player_list;
    delete tmp[player_id];
    this.setState({ player_list: tmp });
  };
  onStart = () => {
    this.setState({
      status: "start",
    });
  };
  onUpdateBoard = (game_id, board, last_move=[]) => {
    let tmp = this.state.board;
    tmp[game_id] = board;
    let tmp2 = this.state.last_move;
    tmp2[game_id] = last_move;
    let tmp3 = this.state.board_end;
    tmp3[game_id] = false;
    this.setState({ board: tmp, last_move: tmp2, board_end: tmp3 });
  };
  onUpdateTimeLimit = (game_id, black_time_limit, white_time_limit) => {
    let tmp = this.state.time_limit;
    tmp[game_id] = { black: black_time_limit, white: white_time_limit };
    this.setState({ time_limit: tmp });
  };
  onUpdateStoneCount = (game_id, black_stone_count, white_stone_count) => {
    let tmp = this.state.stone_count;
    tmp[game_id] = { black: black_stone_count, white: white_stone_count };
    this.setState({ stone_count: tmp });
  };
  onUpdateWinTimes = (game_id, black_win_times, white_win_times) => {
    let tmp = this.state.win_times;
    tmp[game_id] = { black: black_win_times, white: white_win_times };
    this.setState({ win_times: tmp });
  };
  onUpdateScoreboard = (scoreboard) => {
    this.setState({ scoreboard: scoreboard });
  };
  onUpdateTree = (game_tree) => {
    this.setState({ game_tree: game_tree });
  };
  onEndGame = (game_id) => {
    let tmp = this.state.board_end;
    tmp[game_id] = true;
    this.setState({ board_end: tmp });
  };
  onEndCompetition = () => {};
  onChangeColor = (game_id, black, white) => {
    let tmp = this.state.player_color;
    tmp[game_id] = { black: black, white: white };
    this.setState({ player_color: tmp });
  };

  initHistory = async (data) => {
    let tmp_player_list = this.state.player_list;
    data.players.forEach((player) => {
      tmp_player_list[player] = undefined;
    });
    let tmp_history_time = {};
    Object.entries(data.games).map(([key, value]) => {
      tmp_history_time[key] = { time: 0, max: value.length - 1 };
      this.onUpdateBoard(key, value[0].board);
      this.onChangeColor(key, value[0].black.name, value[0].white.name);
      this.onUpdateTimeLimit(key, value[0].black.time_limit, value[0].white.time_limit );
      this.onUpdateStoneCount(key, value[0].black.stone_count, value[0].white.stone_count);
      this.onUpdateWinTimes(key, value[0].black.win_times, value[0].white.win_times);
    });
    this.setState({ 
      player_list: tmp_player_list, 
      game_tree: data.game_tree, 
      scoreboard: data.scoreboard,
      history: data.games,
      history_time: tmp_history_time,
      initialize: true,
      status: "end"
    });
  };
  loadHistory = (game_id, time) => {
    let tmp = this.state.history_time;
    tmp[game_id].time = time;
    this.onUpdateBoard(game_id, this.state.history[game_id][time].board);
    this.onChangeColor(
      game_id,
      this.state.history[game_id][time].black.name,
      this.state.history[game_id][time].white.name
    );
    this.onUpdateTimeLimit(
      game_id,
      this.state.history[game_id][time].black.time_limit,
      this.state.history[game_id][time].white.time_limit
    );
    this.onUpdateStoneCount(
      game_id,
      this.state.history[game_id][time].black.stone_count,
      this.state.history[game_id][time].white.stone_count
    );
    this.onUpdateWinTimes(
      game_id,
      this.state.history[game_id][time].black.win_times,
      this.state.history[game_id][time].white.win_times
    );
    this.setState({ history_time: tmp });
  };
  async componentDidMount() {
    try {
      let data = await competition_info(this.props.match.params["id"]);
      data = JSON.parse(data);
      this.setState({
        type: data.type,
        board_size: data.board_size
      });
      if (data.status === "ended") {
        data = await competition_history(this.props.match.params["id"]);
        data = JSON.parse(data);
        this.initHistory(data);
      } else {
        this.ws = await ws_connect(
          this.props.match.params["id"],
          this.onInit,
          this.onAddPlayer,
          this.onRemovePlayer,
          this.onStart,
          this.onUpdateBoard,
          this.onUpdateTimeLimit,
          this.onUpdateStoneCount,
          this.onUpdateWinTimes,
          this.onUpdateScoreboard,
          this.onUpdateTree,
          this.onEndGame,
          this.onEndCompetition,
          this.onChangeColor
        );
      }
    } catch (e) {}
  }
  componentWillUnmount() {
    try {
      this.ws.close();
    } catch (e) {}
  }

  render() {
    return this.state.initialize ? (
      this.state.status !== "prepare" ? (
        <CompetitionStart
          competition_id={this.props.match.params["id"]}
          is_login={this.props.is_login}
          status={this.state.status}
          type={this.state.type}
          board_size={this.state.board_size}
          player_list={this.state.player_list}
          board={this.state.board}
          last_move={this.state.last_move}
          board_end={this.state.board_end}
          time_limit={this.state.time_limit}
          stone_count={this.state.stone_count}
          win_times={this.state.win_times}
          scoreboard={this.state.scoreboard}
          game_tree={this.state.game_tree}
          player_color={this.state.player_color}
          history_time={this.state.history_time}
          loadHistory={this.loadHistory}
        ></CompetitionStart>
      ) : (
        <CompetitionPrepare
          id={this.props.match.params["id"]}
          is_login={this.props.is_login}
          player_list={this.state.player_list}
        ></CompetitionPrepare>
      )
    ) : null;
  }
}

export default hot(module)(Competition);
